<template>
  <div>
    <div class="login-frame">
      <div class="middle-box">
        <a href="http://www.openprisecloud.com">
          <img class="img-responsive" src="@/assets/Openprise_Logo_TM.png" alt="Tenant Logo" width="350"/>
        </a>
        <span class="openprise-subtitle">
          <p>RevOps Data Automation Cloud</p>
          <p>Workbench</p>
        </span>
        <form name="login" role="form" action="/auth/LoginRedirect" method="POST">
          <input v-model="email" type="email" ref="email" class="form-control content-margin"
            placeholder="Email" id="inputEmail" name="username" required>
          <button type="submit" class="btn" :disabled="!email">Next</button>
        </form>
        <div class="links">
          <div class="contact-us">
            <a href="mailto:support@openprisetech.com" class="login-link">Contact us</a>
          </div>
          <div class="privacy-policy">
            <a href="https://www.openprisetech.com/privacy/product/" target="_blank" class="login-link">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
    <div class="div-iframe mobile-hide">
      <iframe :src="splashUrl" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      email: '',
      splashUrl: ''
    }
  },
  async mounted () {
    this.$refs.email.focus()
    const response = await fetch('/daass/login/getSplashDetails')
    const {data} = await response.json()
    if (data && data.url) {
        this.splashUrl = data.url
    } else {
        this.splashUrl = 'https://www.openprisetech.com/html/marketing-login.php'
    }
  }
}
</script>

<style scoped>
  .content-margin {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .login-link{
    color: #fff;
    text-decoration: none;
  }
  .middle-box {
    max-width: 350px;
    margin: 0 auto;
    padding-top: 90px;
    text-align: center;
    color: white;
  }
  .div-iframe {
    width: calc(100% - 480px);
    float: left;
  }
  .login-frame {
      width: 480px;
      float: left;
  }
  iframe {
      display: block;       /* iframes are inline by default */
      background: #000;
      height: 100vh;        /* Viewport-relative units */
      width: 100%;
  }
  @media only screen and (max-width: 991px) {
    .mobile-hide{ display: none !important; }
    .login-frame {
      width: auto !important;
      float: none !important;
    }
  }
  .form-control {
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    box-sizing : border-box;
    color: #676a6c;
  }
  .btn {
    background-color: #5cb85c;
    border-color: #4cae4c;
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 100%;
    color: white;
    cursor: pointer;
  }
  .btn[disabled] {
    opacity: .65;
  }
  .links {
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin-top: 20px;
  }
  .links .contact-us {
    padding-right: 10px;
    border-right: 1px solid white;
  }
  .links .privacy-policy {
    margin-left: 10px;
  }
  .openprise-subtitle {
    font-size: 17px;
  }
  .openprise-subtitle p{
    margin-top: 0px;
  }
</style>
