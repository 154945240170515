import Vue from "vue";
import VueRouter from "vue-router";
import Login from '@/components/login.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Login,
    alias: '/daass/auth'
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
