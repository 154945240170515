import Vue from 'vue'
import App from './App.vue'
import router from "./router"

Vue.config.productionTip = false

// If pod url cookie is available then no need to create the vue instance.
const cookies = document.cookie.split(';')
const podUrlCookie = cookies.find((item) => item.includes('OpPodUrl='))
if (podUrlCookie) {
  let cookieValue
  cookieValue = podUrlCookie.split('=')[1]
  if (cookieValue) {
    cookieValue = JSON.parse(decodeURIComponent(cookieValue))
    window.location.href = `${cookieValue.url}login/password?username=${cookieValue.username}`
  }
} else {
  // Creating the vue instance.
  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app')
}
